<template>
    <div class="grid">
      <div class="col-12">
        <div class="card">
          <Toast />
          <Toolbar class="mb-4">
            <template v-slot:start>
              <div class="my-2">

              </div>
            </template>
  
            <template v-slot:end>
              <Button
                label="Ver deudas por cliente"
                icon="pi pi-user"
                class="p-button-help mx-2"
                @click="productDialog = true"
              />

              <Button
                label="Exportar"
                icon="pi pi-upload"
                class="p-button-help"
                @click="exportCSV($event)"
              />
            </template>
          </Toolbar>
  
          <DataTable
            ref="dt"
            :value="cotizaciones"          
            dataKey="id"
            :paginator="true"
            :rows="10"
            :filters="filters"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[5, 10, 25]"
            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} cotizaciones"
            responsiveLayout="scroll"
          >
            <template #header>
              <div
                class="
                  flex flex-column
                  md:flex-row md:justify-content-between md:align-items-center
                "
              >
                <h5 class="m-0">Contabilidad</h5>
                <span class="block mt-2 md:mt-0 p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText
                    v-model="filters['global'].value"
                    placeholder="Buscar..."
                  />
                </span>

                <div>
                    <!-- <h1> (IDEA) LIBRO DEBE/HABER, FILTROS POR CLIENTE,</h1> -->
                </div>
              </div>
            </template>
  
            <Column
              field="id"
              header="#"
              :sortable="true"
              headerStyle="width:5%; min-width:7rem;"
            >
              <template #body="slotProps">
                <span class="p-column-title">#</span>
                {{ slotProps.data.id }}
              </template>
          </Column>
  
            <Column
              field="created_at"
              header="Fecha"
              :sortable="true"
              headerStyle="width:10%; min-width:10rem;"
            >
              <template #body="slotProps">
                <span class="p-column-title">Fecha</span>
                {{ slotProps.data.created_at }}
              </template>
          </Column>
  
            <Column
              field="client_name"
              header="Cliente"
              :sortable="true"
              headerStyle="width:20%; min-width:4rem;"
            >
              <template #body="slotProps">
                <span class="p-column-title">Cliente</span>
                {{ slotProps.data.client_name}} 
              </template>
          </Column>
              <Column
                  field="status"
                  header="Estado"
                  :sortable="true"
                  headerStyle="min-width:10rem;"
                  >
                  <template #body="slotProps">
    <div class="flex align-content-center ">
  
      <div v-if="!slotProps.data.vencida">
  
        <Button
                        icon="pi pi-envelope"
                        class="p-button-rounded p-button-info mr-1 p-button-icon-only"
                        @click="product = slotProps.data; sendMailDialog = true"
                      />
      </div>
  <!-- 
                      <div :class="'status_box status-' + slotProps.data.status" v-if="!slotProps.data.vencida">
                      
                      <div class="" >
                        <Button
                          icon="pi pi-check"
                          class="p-button-rounded p-button-text  p-button-success"
                          @click="accept(slotProps.data)"
                        />
                        {{ statusName(slotProps.data.status) }}
  
                      </div>
  
                      
                      
                      </div>
  
                      <div :class="'status_box status-2'" v-else> 
                        <div class="w-100">
                          
                          VENCIDA 
                        </div> 
                      </div>   -->
  
  
                  </div>
                  </template>
              </Column>
            <Column headerStyle="min-width:10rem;">
              <template #body="slotProps">
                <Button
                  icon="pi pi-eye"
                  class="p-button-rounded p-button-primary mr-2"
                  @click="showPdf(slotProps.data.id)"
                />
                <Button
                  icon="pi pi-file-pdf"
                  class="p-button-rounded p-button-danger mr-2"
                  @click="downloadPdf(slotProps.data.id)"
                />
                <Button
                  icon="pi pi-pencil"
                  class="p-button-rounded p-button-success mr-2"
                  @click="editProduct(slotProps.data)"
                />
                <Button
                  icon="pi pi-trash"
                  class="p-button-rounded p-button-warning mt-2"
                  @click="confirmDeleteProduct(slotProps.data)"
                />
              </template>
            </Column>
          </DataTable>
  
          <!--- MODAL CREAR -->
          <Dialog
            v-model:visible="productDialog"
            :style="{ width: '450px' }"
            header="Listado de Deudas"
            :modal="true"
            class="p-fluid"
          >
            <div class="field">
              <label for="name">Nombre del cliente </label>
              <InputText
                id="name"
                v-model.trim="product.client"
                required="true"
                autofocus
                :class="{ 'p-invalid': submitted && !product.client }"
              />
              <small class="p-invalid" v-if="submitted && !product.client"
                >Cliente es requerido.</small
              >
            </div>

            <div>
              <label for="name">Hasta la fecha</label>
              <Calendar
                id="name"
                v-model="product.hasta"
                required="true"
                autofocus
                :class="{ 'p-invalid': submitted && !product.hasta }"
              />
              <small class="p-invalid" v-if="submitted && !product.hasta"
                >Fecha hasta es requerida.</small
              >
            </div>
            
  
            <template #footer>
              <Button
                label="Cancelar"
                icon="pi pi-times"
                class="p-button-text"
                @click="hideDialog"
              />
              <Button
                label="Ver reporte"
                icon="pi pi-check"
                class="p-button-text"
                @click="getReport"
              />
            </template>
          </Dialog>
          <!--- MODAL CREAR -->
    <Dialog
            v-model:visible="sendMailDialog"
            :style="{ width: '450px' }"
            header="Confirmación"
            :modal="true"
          >
            <div class="text-center">
              <i
                class="pi pi-exclamation-triangle mr-3"
                style="font-size: 2rem"
              />
              <span v-if="product"
                > Envíar mail al cliente por la cotización N° <b> {{ product.id }} </b>
                ?</span
              >
              <p>
                </p>
                Se envíara a <b> {{ product.client.email }} </b>
                
              
  
  
            </div>
            <template #footer>
              <Button
                label="No"
                icon="pi pi-times"
                class="p-button-text"
                @click="sendMailDialog = false"
              />
              <Button
                label="Sí"
                icon="pi pi-check"
                class="p-button-text"
                @click="this.sendMailConfirm(product.id, product.client.email)"
              />
            </template>
          </Dialog>
  
          <Dialog
            v-model:visible="deleteProductDialog"
            :style="{ width: '450px' }"
            header="Confirmación"
            :modal="true"
          >
            <div class="flex align-items-center justify-content-center">
              <i
                class="pi pi-exclamation-triangle mr-3"
                style="font-size: 2rem"
              />
              <span v-if="product"
                > Eliminaras la cotización N° <b> {{ product.id }} </b>
                ?</span
              >
            </div>
            <template #footer>
              <Button
                label="No"
                icon="pi pi-times"
                class="p-button-text"
                @click="deleteProductDialog = false"
              />
              <Button
                label="Sí"
                icon="pi pi-check"
                class="p-button-text"
                @click="deleteProduct"
              />
            </template>
          </Dialog>
  
          <Dialog
            v-model:visible="deleteProductsDialog"
            :style="{ width: '450px' }"
            header="Confirm"
            :modal="true"
          >
            <div class="flex align-items-center justify-content-center">
              <i
                class="pi pi-exclamation-triangle mr-3"
                style="font-size: 2rem"
              />
              <span v-if="product">Seguro?</span>
            </div>
            <template #footer>
              <Button
                label="No"
                icon="pi pi-times"
                class="p-button-text"
                @click="deleteProductsDialog = false"
              />
              <Button
                label="Sí"
                icon="pi pi-check"
                class="p-button-text"
                @click="deleteSelectedProducts"
              />
            </template>
          </Dialog>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { FilterMatchMode } from "primevue/api";
  import CrudService from "./../../services/crud.service";
  import emailService from "./../../services/email.service";
  
  export default {
    data() {
      return {
        routeName: null,
        cotizaciones: [
         
        ],
        sendMailDialog: false,
        productDialog: false,
        deleteProductDialog: false,
        deleteProductsDialog: false,
        product: {
          // format to yyyy-d-m
          desde: new Date().setDate(new Date().getDate() - 30).toString().split('T')[0],
          hasta: new Date().setDate(new Date().getDate() + 30).toString().split('T')[0],
          cliente: 'Todos'
        },
        selectedProducts: null,
        filters: {},
        submitted: false,
        validationErrors: null,
        providers: [],
        sendingMail: false,
        emailModalData: null,
      };
    },
     
    created() {
      // get route name
      this.routeName = this.$route.name;
       
      this.initFilters();
    },
    mounted() {
       CrudService.getCRUD('api/contabilidad').then(
         (data) => (this.cotizaciones = data)
      ).catch(
        (error) => console.log(error)
      );
      // CrudService.getCRUD('api/clients').then(
      //   (data) => (this.providers = data)
      // );
  
    },
    methods: {
      sendMailConfirm(id, email) {
        // confirm dialog
        this.emailModalData = {
          id: id,
          email: email,
          type: "cotizacion",
          
        };
  
        this.sendMail()
  
  
      },
        sendMail() {
  
        this.sendingMail = true;
  
          emailService
          .sendCotizacion(this.emailModalData)
          .then((response) => {
  
  
            console.log(response);
  
            // find this.emailModalData.id in this.customers
  
            // this.customers.forEach((item, index) => {
            //   if (item.id == this.emailModalData.id) {
            //     this.customers[index].mailed = 1;
            //   }
            // });
            // set this.customers.mailed = true
  
            this.$toast.add({
              severity: "success",
              summary: "Enviado",
              detail: "Correo enviado",
              life: 3000,
            });
            //this.closeEmailModal();
            this.sendingMail = false;
          })
          .catch((error) => {
            console.log(error);
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: "Error al enviar el correo",
              life: 3000,
            });
            this.sendingMail = false;
          });
      },
  
      accept(budget){
        console.log(budget.id)
        CrudService.updateCRUD('api','cotizacion/accept', budget).then(
          (data) => {
            const index = this.cotizaciones.findIndex(
              (item) => item.id === budget.id
            );
  
            // update budget in array
            this.cotizaciones[index] = data.data;
          }
        ).catch(
          (error) => console.log(error)
        );
      
      },
      reject(budget){
        CrudService.updateCRUD('api','cotizacion/reject', budget).then(
          (data) => {
            // find index of budget in array
            const index = this.cotizaciones.findIndex(
              (item) => item.id === budget.id
            );
  
            // update budget in array
            this.cotizaciones[index] = data.data;
          }
        ).catch(
          (error) => console.log(error)
        );
      
      },
      
      statusName(value){
  
        // convert value to integer
        value = parseInt(value);
  
        switch (value) {
  
          case 0:
            return 'Pendiente';
            break;
          case 1:
            return 'Enviada';
            break;
          case 2:
            return 'Vencida';
            break;
          case 3:
            return 'Aprobada';
            break;
          case 4:
            return 'Rechazada';
            break;
          default:
            return 'Pendiente';
            break;
          }
      },
      giveMeName(id) {
        let exist = this.providers.find(provider => provider.id === Number(id));
  
        if(!exist) {
          return ' - ';
        }else {
          return exist.name;
        }
      },
      afipType(value) {
        if (value == 1) {
          return "Responsable Inscripto";
        }
        if (value == 2) {
          return "Monotributista";
        }
        if (value == 3) {
          return "Exento";
        }
        if (value == 4) {
          return "Consumidor Final";
        }
  
        return " - ";
      },
      formatCurrency(value) {
        if (value)
          return value.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          });
        return;
      },
      openNew() {
        // go to route cotizaciones/create
        this.$router.push({ name: "CreateBudget" });
      },
      hideDialog() {
        this.productDialog = false;
        this.submitted = false;
      },
      displayErrors(errors) {
        
                for (let key in errors) {
  
                  this.validationErrors.push({ "field": key, "message": errors[key] });
  
                  let error = errors[key];
                  this.$toast.add({
                    severity: "error",
                    summary: "Error!",
                    detail: error[0],
                    life: 3000,
                  });
                }
      },
      getReport() {
        this.submitted = true;
        this.productDialog = false;
        
        let anchor = document.createElement("a");
        document.body.appendChild(anchor);
        let url = this.appBackend + `/api/contabilidad/report?from=${this.product.desde}&to=${this.product.hasta}&client=${this.product.client}`;

        // new window
        anchor.target = "_blank";
        anchor.href = url;

        // click

        anchor.click();

        
      },
      editProduct(product) {
        // this.product = { ...product };
        // this.productDialog = true;
  
        alert('en desarrollo')
      },
      showPdf(id) { 
  
        let anchor = document.createElement("a");
        document.body.appendChild(anchor);
        let url = this.appBackend + `/api/cotizacion/${id}/show`;
  
        // new window
        anchor.target = "_blank";
        anchor.href = url;
  
        // click
        anchor.click();
  
  
  
      },
      downloadPdf(id) { 
  
        let anchor = document.createElement("a");
        document.body.appendChild(anchor);
        let file = this.appBackend + `/api/cotizacion/${id}/pdf`;
  
        let headers = new Headers();
        headers.append('Authorization', 'Bearer ' + localStorage.getItem("api_token")['api_token']);
  
        fetch(file, { headers })
            .then(response => response.blob())
            .then(blobby => {
                let objectUrl = window.URL.createObjectURL(blobby);
  
                anchor.href = objectUrl;
                anchor.download =  'C_' + id + '_MF.pdf';
                anchor.click();
  
                window.URL.revokeObjectURL(objectUrl);
            });
  
  
      },
      confirmDeleteProduct(product) {
        this.product = product;
        this.deleteProductDialog = true;
      },
      deleteProduct() { 
  
        CrudService.deleteCRUD('api', 'cotizacion', this.product.id).then(
          (data) => {
            
            this.$toast.add({
              severity: "success",
              summary: "Éxito!",
              detail: "Eliminado correctamente.",
              life: 3000,
            });
          }
        );
  
        this.cotizaciones = this.cotizaciones.filter((val) => val.id !== this.product.id);
  
        this.deleteProductDialog = false;
        this.product = {};
  
      },
      findIndexById(id) {
        let index = -1;
        for (let i = 0; i < this.products.length; i++) {
          if (this.products[i].id === id) {
            index = i;
            break;
          }
        }
        return index;
      },
      createId() {
        let id = "";
        var chars =
          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (var i = 0; i < 5; i++) {
          id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
      },
      exportCSV() {
        this.$refs.dt.exportCSV();
      },
      confirmDeleteSelected() {
        this.deleteProductsDialog = true;
      },
      deleteSelectedProducts() {
        this.products = this.products.filter(
          (val) => !this.selectedProducts.includes(val)
        );
        this.deleteProductsDialog = false;
        this.selectedProducts = null;
        this.$toast.add({
          severity: "success",
          summary: "Éxito!",
          detail: "Eliminado correctamente.",
          life: 3000,
        });
      },
      initFilters() {
        this.filters = {
          global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        };
      },
    },
  };
  </script>
  
   
  <style scoped>
          .status_box {
            display: inline-block;
            line-height: 2rem;
                width: 100%;
            font-weight: bolder;
            text-align: center;
            padding: 5px;
            text-transform: uppercase;
          }
          .status-4 {
              border: 1px solid #74171171;
              background-color: #cc050571;
          }
          .status-3 {
              border: 1px solid #44b73a71;
              background-color: #44b73a71;
          }
          .status-2 {
              border: 1px solid #ff99005b;
              background-color: #ff99005b;
          }
          .status-1 {
              border: 1px solid #1c96c256;
              background-color: #1c96c256;
            
          }
          .status-0 {
              border: 1px solid #d8d8d89a;
              background-color: #d8d8d89a;
          }
  </style>
  